import spell_translate from './spell_translate.js'

async function get_spell_name(spellName, globalState) {

  console.log('spellName:', spellName);
  console.log('globalState:', globalState);

  // try to get the bonus value if it there

  let match = spellName.match(/^\+\d+|\+\d+$/);
  let bonus = match ? match[0] : "";

  spellName = spellName.trim();
  spellName = spellName.replace(/^\*+|\*+$/g, '');  // removes * at the beginning and end of the string
  spellName = spellName.replace(/\+\d+$/, '');        // removes +1 and +17 at the end of the string
  spellName = spellName.replace(/^\+\d+\s/, '');       // remove leading +1, +10 etc
  spellName = spellName.replace(/\(.*|\d+\s*$/, '');    // removes (5) and 1 type text
  // remove 'RH' or 'LH' from the end of the string
  spellName = spellName.replace(/(RH|LH)$/, '');
  
  spellName = spellName.replace(/.*(?=Giant Strength)/, ''); // removes 'Fire' from 'Fire Giant Strength'
  spellName = spellName.replace(/.*(?=Dragon Control)/, '');
  spellName = spellName.replace(/.*: /, '');  // remove anything before a ': ' (for Scr: Protection...)
  spellName = spellName.trim();

  let fullSpellName = await(spell_translate(spellName));
  console.log('fullSpellName:', fullSpellName);

    if ( fullSpellName === 'Spell not found' | fullSpellName === 'Error getting spell lookup' ){
        const allSpells = globalState[7][0];
        const spellList = allSpells.map(spell => spell.Name);
        // console.log('spellList: ', spellList);
        
        const closestMatches = findClosestMatches(spellName, spellList);
        console.log('closestMatches:', closestMatches);
        // check to see if there is an exact match first
        
        fullSpellName = closestMatches[0].name;
    }
    console.log('fullSpellName:', fullSpellName);

    // fix the spell name if it includes a '&' character
    // fullSpellName = fullSpellName.replace(/&amp;/g, '&');

  return fullSpellName;
}


function normalizeString(str) {
  return str.toLowerCase().trim().replace(/[^a-z0-9\s]/g, '').replace(/\s+/g, ' ');
}

/**
* An improved Levenshtein distance calculation that better handles abbreviations
* Returns a numerical distance value like the original levenshtein function
* 
* @param {string} a - First string to compare
* @param {string} b - Second string to compare
* @return {number} - Modified Levenshtein distance (lower = more similar)
*/


function improvedLevenshtein(a, b, abbreviationBoost = 1) {
  /*
  console.log('a:', a);
  console.log('b:', b);

  // check for roman numerals
  this code fails because we're stripping out numbers first

  function romanToNumber(roman) {
    const map = {
      I: 1, II: 2, III: 3, IV: 4, V: 5,
      VI: 6, VII: 7, VIII: 8, IX: 9, X: 10
    };
    return map[roman.toUpperCase()] || null;
  }
  
  function replaceRomanSuffix(str) {
    const romanRegex = /\s+(I|II|III|IV|V|VI|VII|VIII|IX|X)$/i;
    const match = str.match(romanRegex);
    if (match) {
      const num = romanToNumber(match[1]);
      if (num !== null) {
        return str.replace(romanRegex, ' ' + num);
      }
    }
    return str;
  }
  
  a = replaceRomanSuffix(a);
  b = replaceRomanSuffix(b);

  console.log('a:', a);
  console.log('b:', b);
  */


  // Normalize inputs
  a = (a || '').toLowerCase().trim();
  b = (b || '').toLowerCase().trim();
  
  // Standard cases
  if (a === b) return 0;
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;
  
  // Check for abbreviation patterns
  const aWords = a.split(/\s+/);
  const bWords = b.split(/\s+/);      

  // Case: First-letter abbreviation pattern (e.g., "comp lang" vs "comprehend languages")
  let isAbbrev = false;
  let abbrevStrength = 0;
  
  // Check if a could be abbreviation of b
  if (aWords.length === bWords.length) {
      const matchingWords = aWords.filter((word, i) => {
          const bWord = bWords[i];
          return bWord.startsWith(word) || (word.length === 1 && bWord.startsWith(word));
      }).length;
      
      if (matchingWords === aWords.length) {
          isAbbrev = true;
          abbrevStrength = Math.min(3, matchingWords) / 3; // Scale based on match count
      }
  }
  
  // Check if b could be abbreviation of a
  /*if (!isAbbrev && aWords.length === bWords.length) {
  //    const matchingWords = bWords.filter((word, i) => {
          const aWord = aWords[i];
          return aWord.startsWith(word) || (word.length === 1 && aWord.startsWith(word));
      }).length;
      
      if (matchingWords === bWords.length) {
          isAbbrev = true;
          abbrevStrength = Math.min(3, matchingWords) / 3; // Scale based on match count
      }
  }
  */

  // Check if a is initials of b
  if (!isAbbrev) {
      const bInitials = bWords.map(w => w[0]).join('');
      if (a.replace(/\s+/g, '') === bInitials) {
          isAbbrev = true;
          abbrevStrength = 0.9; // Strong match for initials
      }
  }
  
  // Check if b is initials of a
  if (!isAbbrev) {
      const aInitials = aWords.map(w => w[0]).join('');
      if (b.replace(/\s+/g, '') === aInitials) {
          isAbbrev = true;
          abbrevStrength = 0.9; // Strong match for initials
      }
  }
  
  // Check partial word abbreviations (e.g., "comp" for "comprehend")

  if (!isAbbrev ) {
      if (a.length < b.length) {
          
          for (let i = 8; i >= 4; i--) {
              
              if (b.startsWith(a.substring(0, i))) {
                  
                  isAbbrev = true;
                  if (i >= 6) {
                      abbrevStrength = 0.8;
                  } else if (i === 5) {
                      abbrevStrength = 0.7;
                  } else if (i === 4) {
                      abbrevStrength = 0.6;
                  } else {
                      abbrevStrength = 0.5; // Default value for less than 4 characters
                  }
                  break;
              }
          }
      
      } else if (b.length < a.length && a.startsWith(b)) {
          isAbbrev = true;
          abbrevStrength = 0.8; // Strong match for single word prefix
      }
  }
  
  // Apply abbreviation bonus if detected
  if (isAbbrev) {
      // Calculate standard Levenshtein first to get baseline
      const standardDist = standardLevenshtein(a, b);
      // Apply the configurable boost - higher boost means more abbreviation preference
      return standardDist * (1 - (abbrevStrength * abbreviationBoost));
  }
  
  // Fall back to standard Levenshtein with word-aware improvements
  return standardLevenshtein(a, b);
}



/**
* Standard Levenshtein distance calculation with minor word-boundary improvements
* 
* @param {string} a - First string to compare
* @param {string} b - Second string to compare
* @return {number} - Levenshtein distance (lower = more similar)
*/
function standardLevenshtein(a, b) {
  const matrix = [];
  
  // Initialize matrix
  for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
  }
  for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
  }
  
  // Fill matrix
  for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
          const cost = b.charAt(i - 1) === a.charAt(j - 1) ? 0 : 1;
          
          // Standard Levenshtein operations
          let minCost = Math.min(
              matrix[i-1][j] + 1,      // deletion
              matrix[i][j-1] + 1,      // insertion
              matrix[i-1][j-1] + cost  // substitution
          );
          
          // Bonus for prefix matches (word boundaries)
          if (i > 1 && j > 1 && 
              b.charAt(i-1) === a.charAt(j-1) && 
              (i === 1 || b.charAt(i-2) === ' ') && 
              (j === 1 || a.charAt(j-2) === ' ')) {
              minCost = Math.min(minCost, matrix[i-2][j-2] + cost - 0.5);
          }
          
          matrix[i][j] = minCost;
      }
  }
  
  return matrix[b.length][a.length];
}

function findClosestMatches(spellName, spellList) {
  spellName = normalizeString(spellName);

  const matches = spellList.map(spell => {
      return {
          name: spell,
          distance: improvedLevenshtein(spellName, normalizeString(spell))
      };
  });

  matches.sort((a, b) => a.distance - b.distance);

  return matches;
}


export default get_spell_name;