import { React, useState } from 'react';

import NavBar from '../components/NavBar'
import Charsheet05 from './Charsheet05'
import Help from '../components/Help'
import Image01 from '../pdf/CombatActions.jpg'
import RulesWindow from '../components/RulesWindow'
import AllSpells from '../components/AllSpells'

import './LandscapeHome.css'

// ----------------------------------------------------------------------------------
//                         Description
//
//     What does Charsheet01 do?
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------
  

function LandscapeHome(props) {

  // ------------ parameters ---------------
  
  // ------ inherited state variables ------
  console.log('LANDSCAPEHOME props: ', props)

  // ---------- local state variables ----------
  const [rulesWindowSrc, setRulesWindowSrc ] = useState( { title: 'Combat Actions', source: Image01 } )
  
  const [isMobile, setIsMobile] = useState(/Mobi|Tabletop|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  const [userAgent, setUserAgent] = useState(navigator.userAgent);
  const [isTTS, setIsTTS] = useState(/Tabletop/i.test(navigator.userAgent));
  
  
  // ---------- handler functions ----------


  // ---------- return html (jsx) ----------
  return (
  <>
    <div id="top-row" style={{display: 'flex'}}> 
      <NavBar {...props} setRule={ setRulesWindowSrc } className="no-print"/>
      {/* <div id="status" className="noprint"> Width: {window.innerWidth}, Mobile: {isMobile.toString()}, TTS: {isTTS.toString()} </div> */}
    </div>
    <RulesWindow {...props} src={ rulesWindowSrc }></RulesWindow>

    <AllSpells {...props}/>

    <Charsheet05 {...props} />

    <Help />
  </>
  )
}

export default LandscapeHome