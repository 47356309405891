import React, { useEffect, useContext } from 'react';

// import './NavBarButton.css'

import Dropdown from '../common/Dropdown'

import { UserDataContext } from "../../App.js";


// ----------------------------------------------------------------------------------
//                         Description
//
//     Rulesbutton provides a button and drop-down menu for accessing Rules
//  
//     div == .navbaritem
//       button == #nav-menu-rules .nav-menu-button
//         menu items == .menu .rules-menu > ul
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function RulesButton(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------

    // let [currentChar, setChar] = props.state;
    const [userData, setUserData] = useContext(UserDataContext);

    const setRule = props.setRule;

    // -------- local state variables --------

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    

        }

        DoSomething();

    }, [ ]); //useEffect

    // ---------- return html (jsx) ----------

    const restricted_rules = userData.loggedIn ? { /* space3: ['---'],books: ['Players Handbook'] */ }: {}

    const rule_source = {
        tables: ['Combat Actions', 'Fumble Table', 'Saving Throws',  'To Hit & Crit', 'Turn Undead', 'Thief Skills', 'Scroll Failure', 'Class Requirements', 'Class XP Tables'],
        space1: ['---'],
        campaign: ['Campaign Rules 1', 'Campaign Rules 2', 'Bard Class'],
        // spells: ['Spells-Cleric', 'Spells-MagicUser', 'Spells-Druid', 'Spells-Illusionist'],
        space2: ['---'],
        items: [ 'Potions', 'Weapons', 'Armor' ],
        space3: ['---'],
        magicItems: ['Magic Items Table', 'Scrolls', 'Armor and Weapons', 'Magic Rings', 'Rods Staffs Wands', 'Miscellaneous Magic', 'All Magic Items'],
        space4: restricted_rules.space3 || [],
        books: restricted_rules.books || []
      };
      
    
    let rules = [];
    let rules_dir = [];
    for (var source in rule_source) {
        let rule_list = rule_source[source];
        for (const rule of rule_list) {
            rules.push(rule);
            rules_dir.push(source);
        }
    }

    const handleMenu = (selection) => {

        let Img;
        let imgSet = false;

        // TABLES
        if ( rules[selection] === 'Combat Actions' ) {            
            Img = require(`../../pdf/CombatActions.jpg`);
            imgSet = true;
        }

        if ( rules[selection] === 'Fumble Table' ) {
            Img = require(`../../pdf/FumbleTable.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'Saving Throws' ) {
            Img = require(`../../pdf/SavingThrows.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'To Hit & Crit' ) {
            Img = require(`../../pdf/ToHitAndCrit.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'Turn Undead' ) {
            Img = require(`../../pdf/TurnUndead.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'Thief Skills' ) {
            Img = require(`../../pdf/ThiefSkills.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'Scroll Failure' ) {
            Img = require(`../../pdf/ScrollFailure.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'Class Requirements' ) {
            Img = require(`../../pdf/tables/ClassRequirements.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'Class XP Tables' ) {
            Img = require(`../../pdf/tables/ClassXPTables.jpg`)
            imgSet = true;
        }
        

        // CAMPAIGN RULES
        if ( rules[selection] === 'Campaign Rules 1' ) {
            Img = require(`../../pdf/CampaignRules-01.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'Campaign Rules 2' ) {
            Img = require(`../../pdf/CampaignRules-02.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'Bard Class' ) {
            Img = require(`../../pdf/BardClass.jpg`)
            imgSet = true;
        }


        // ITEMS
        if ( rules[selection] === 'Potions' ) {
            Img = require(`../../pdf/items/Potions.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'Armor' ) {
            Img = require(`../../pdf/items/Armor.jpg`)
            imgSet = true;
        }

        if ( rules[selection] === 'Weapons' ) {
            Img = require(`../../pdf/items/Weapons.jpg`)
            imgSet = true;
        }


        // DISPLAY IMAGE IN WINDOW (if set)
        if ( imgSet ) {
            if ( props.setRule ) {
                setRule( { title: rules[selection], source: Img })
            }

            document.getElementById('rulesWindow').style.display = 'block';
            // document.getElementsByClassName('rules-menu')[0].style.display = 'none';
            return;
        }


    // For all other entries, attempt to display a pdf with the same name
    if ( rules[selection] !== '---')  {
    
        var URL = rules_dir[selection] + '/' + rules[selection] + '.pdf';
    
        var Pdf = require(`../../pdf/${URL}`)

        window.open(Pdf, '_blank').focus();
    }
    }
    

    return (
        <>
        <Dropdown
            divClass='navbaritem'
            trigger = {<button id="nav-menu-rules" className="nav-menu-button">Rules</button>}
            menu = { rules.map ( ( rule, idx) => 
                { return <button id={'rules-but-' + idx } onClick={()=>handleMenu(idx)}>{rule}</button>}
            )}
            
            menuClass='menu rules-menu'
        />
        </>
    )

    };  // end of function

    export default RulesButton