import axios from 'axios';

import spell_translate from './spell_translate.js'
import fetch_spell from './fetch_spell.js'
import get_spell_name from './get_spell_name.js'

var txt = ""

const charsheetAPI = 'https://2gy03m48ej.execute-api.us-west-2.amazonaws.com/default/charsheet-access'
const spellTable = 'Spells-Database-1E'

var spellClass = 'Mage';

async function Get01 ( URL, Header = {} ) {
    const res = await axios.get( URL, Header );
    console.log({ RESULT: res });
    return res;
}

async function LOAD_SPELL ( spellName, spellUpdateFunctions, target, globalState ) {

    console.log('LOAD SPELL: ', spellName )

    const updateSpellName = spellUpdateFunctions[0];
    const updateSpellData = spellUpdateFunctions[1];

    if (spellName === undefined) {

        spellName = prompt("Spell name:","");

        if (spellName === null || spellName === "") {
                txt = "User cancelled the prompt.";
                console.log(txt);
                return 'User Cancelled'; }
        else {  spellName = spellName + "" };  
    }    

    // get the full spell name
    console.log("LOAD_SPELL>> " + spellName )
    let fullSpellName = await(get_spell_name(spellName, globalState ));

    console.log("LOAD_SPELL>> " + fullSpellName )


    // -------- CLASS LOOKUPS ---------
    let classChoices = [];
    
    if (['myMag', 'myWeap', 'myArm'].some(v => target.id.includes(v))) {
        classChoices.push('MagicItem')
        classChoices.push('Potion')
    }

    if ( target.id.includes('myOth') ) { 
        classChoices.push('MagicItem')
    }

    if ( target.id.includes('myPot') ) { 
        classChoices.push('Potion')
        classChoices.push('MagicItem')
    }


    const classElement = document.querySelector("#char0-grid-full-myClass-in, #myClass");
    const characterClass = classElement ? classElement.value : null;
    
    if (['Cl','CL','Pa'].some(v => characterClass.includes(v))) {
        classChoices.push('Cleric');
    }

    if (['Ma','Mu','MU', 'Ra'].some(v => characterClass.includes(v))) {
        classChoices.push('Mage');
    }

    if (['Dr','DR','Ra'].some(v => characterClass.includes(v))) {
        classChoices.push('Druid');
    }

    if (['Il','IL'].some(v => characterClass.includes(v))) {
        classChoices.push('Illusion');
    }

    if (!['MagicItem'].some(v => classChoices.includes(v))) {
        classChoices.push('MagicItem');
    }

    if ( classChoices.length < 3 ) {
        classChoices.push('Mage');
    }

    // get character data from dynamoDB
    // console.log( charsheetAPI + '?TableName=' + spellTable + '&Class=' + spellClass + '&Name=' + fullSpellName )

    let thisSpell = await( fetch_spell( fullSpellName, classChoices ) )

    if (thisSpell !== "") {
        // document.getElementById('spell-details').style.display = 'grid';

        // if this is a spell from within an editable link, go up the appropriate number of levels to find the spell div
        // console.log('load_spell[target] = ',target)

        if ( thisSpell === "error") { return }

        if (  Array.from(target.classList).includes('editable-link') ) {
            // git console.log('** editable-link detected **')
            const mobileSpellUI = document.querySelector("#MobileSpellDetail")
            if ( mobileSpellUI ) mobileSpellUI.style.display = 'block';
            document.querySelector("#spell-details").style.display = 'grid';
            target.parentElement.parentElement.parentElement.parentElement.children[1].style.display = 'grid';
        }
        
        updateSpellData(thisSpell);
    }
    
}


export default LOAD_SPELL