import React, { useState, useEffect, useContext } from 'react';

// import './NavBarButton.css'

import Dropdown from '../common/Dropdown'

// import LOAD_CHAR from '../Charsheet0/helpers/load_char.js'

import { UserDataContext } from "../../App.js";

// ----------------------------------------------------------------------------------
//                         Description
//
//     SettingsButton is a temporary placeholder for now. Will eventually navigate to a new page
//  
//     div == .navbaritem
//       button == #nav-menu-settings .nav-menu-button
//         menu items == .menu .spells-menu > li > button
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

const handleDiscordLogin = () => {
    
        // get the hostname
        var currentHost = window.location.host;

        // remove the www. if it's there
        if (currentHost.slice(0, 3) === "www" ) {
            currentHost = currentHost.slice(4);
        }

        // set up the auth & callback URL
        var discordAuthUrl = "https://discord.com/api/oauth2/authorize?client_id=1084196359898415255&response_type=token&scope=identify&"
        
        discordAuthUrl += "redirect_uri=http://" + currentHost + "/oauth2/callback/"

        // open discord to allow user to authenticate
        window.open(discordAuthUrl, '_self');
  };

  /* const handleDiscordLogout = () => {
    localStorage.removeItem("discord_token");
    setUserData({username: 'Log In', loggedIn: false})
  }; */

function SettingsButton(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------
    let [currentChar, setChar] = [ props.state[0], props.state[1] ];
    let [layout, setLayout] = [ props.state[3], props.state[4] ];
    const [userData, setUserData] = useContext(UserDataContext);

    // -------- local state variables --------

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    
          
        }

        DoSomething();

    }, [] ); //useEffect

    // ---------- return html (jsx) ----------

    // let menuOptions = [userData.username,'Change Campaign','View N characters', 'Play Songs', 'Campaign Discord', 'Development Discord', 'Help'] ;
    let menuOptions = [
        userData.username,
        'View > Mobile',
        'View > Portrait',
        'View > Landscape',
        'View N characters',
        ...(userData.loggedIn ? ['Play Songs'] : []),
        'Campaign Discord',
        'Development Discord',
        'Help'
      ];
      
    let gearIcon = require('../../img/gear.png');

    const handleMenu = (selection) => {
        // Implement the various menu options here

        // ----------  LOG IN --------------
        if (menuOptions[selection] === 'Log In') {
            handleDiscordLogin();
        }


        // ----------  LOG OFF --------------
        else if (menuOptions[selection] === userData.username) {
            localStorage.removeItem("discord_token");
            setUserData({username: 'Log In', loggedIn: false});
        }

        // ----------  VIEW MOBILE --------------
        else if (menuOptions[selection] === 'View > Mobile') {
            setLayout("mobile")
        }


        // ----------  VIEW PORTRAIT --------------
        else if (menuOptions[selection] === 'View > Portrait') {
            setLayout("full")
        }

        // ----------  VIEW LANDSCAPE --------------
        else if (menuOptions[selection] === 'View > Landscape') {
            setLayout("landscape")
        }
        
        // ----------  CHANGE CAMPAIGN --------------
        else if (menuOptions[selection] === 'Change Campaign') {
            const textToShow = "Future feature\n\n" + userData.username + "  " + ( userData.loggedIn ? "logged in" : "needed" )
            alert(textToShow)
            console.log(userData);
        }
        
        
        // ----------  CAMPAIGN DISCORD --------------
        else if (menuOptions[selection] === 'Campaign Discord') {
            let URL = 'https://discord.com/channels/967890481105223700/967890730083311656'
            window.open(URL, '_blank').focus();
        }


        // ----------  DEV DISCORD --------------
        else if (menuOptions[selection] === 'Development Discord') {
            let URL = 'https://discord.com/channels/1063859029874589787/1063859030772174865'
            window.open(URL, '_blank').focus();
        }


        // --------------------  VIEW N ------------------------        
        else if (menuOptions[selection] === 'View N characters') {
            
            const showNChars = () => {
                const char01 = document.getElementById("charsheet-full");
                const char05 = document.getElementById("char-form-05");

                if (char05.style.display === 'none') {

                    let nr_chars = prompt("Number of characters to show:", '5' );
                    if (isNaN ( parseInt(nr_chars) ) ) { nr_chars = 5; }
                    else { nr_chars = Math.max( 1, parseInt(nr_chars) ) }
                    nr_chars = Math.min( 20, nr_chars )

                    char05.style.display = 'flex';
                    char01.classList.add('no-print');
                    char05.classList.remove('no-print');


                    // show characters 1..nr_chars
                    for ( let i=1; i <= nr_chars; i++) {
                        document.getElementById('char' + i + '-div').style.display = 'inline';
                    }
                    // hide characters nr_chars..20
                    for ( let i=nr_chars+1; i <= 20; i++ ) {
                        document.getElementById('char' + i + '-div').style.display = 'none';
                    }

                } else {

                    char05.style.display = 'none';
                    char05.classList.add('no-print');
                    char01.classList.remove('no-print');

                }
            } 
            
            if (layout !== "full") {
                setLayout("full")
                setTimeout(function(){
                    showNChars()
                }, 1000);
            } else {
                showNChars()
            }

               

        } 


        // ----------  PLAY SONGS --------------
        else if (menuOptions[selection] === 'Play Songs') {

            // toggle #songPlayerDiv display on/off

            var songPlayerDiv = document.getElementById('songPlayerDiv');

            if ((songPlayerDiv.style.display === 'none') || (songPlayerDiv.style.display === '')) {
                songPlayerDiv.style.display = 'flex';
            } else {
                songPlayerDiv.style.display = 'none';
            }
        } 


        // -------------- HELP ------------------
        else {
        
        if ( menuOptions[selection] === 'Help' ) {

            document.getElementById('help-menu').style.display = 'flex';

            } else {
                alert('Future feature');
            }
        }


    }
    
    // ------------  Return JSX  ----------------
    
    return (
    
        <>
        <Dropdown
            divClass='navbaritem'
            trigger = {<button id="nav-settings-button" className="nav-menu-button"><div id='settings-text'>Settings </div><div id='gear-icon-div'><img src={gearIcon} alt='Settings' id='gear-icon'/></div></button>}
            menu = { menuOptions.map ( ( rule, idx) => 
                { return <button id={'settings-option-' + idx } onClick={()=>handleMenu(idx)}>{rule}</button>}
            )}
            menuClass='menu settings-menu'
        />
        </>
    )

    };  // end of function

    export default SettingsButton