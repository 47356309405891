import React, { useState, useEffect } from 'react';

import sanitizeInput from '../../../helpers/sanitizeInput';

import EditableLink from '../EditableLink';

import SpellCheckGroup from '../../SpellCheckGroup.js';

import './Flexigrid2.css'

// ----------------------------------------------------------------------------------
//                         Description
//
//     Flexigrid2 is a grid of input cells, defined by props.cellsHTML and props.cells
//
//     props.cells is the original implementation and creates simple input cells and boxes (boxes are borders)
//        cells = { datafield1: [ X1, X2, Y1, Y2 ], datafield2: ... }}
//        boxes = { box1: [ X1, X2, Y1, Y2 ], box2: ... }
//        data = { datafield1: 'value1', datafield2: ... }
//          data is normally tied to a character data state variable
//          when an input field is changed, data is updated using the OnDataChange function
//
//     props.cellsHTML is a newer implementation that includes cell types. It is an array of dictionaries
//        cellsHTML = [ { name: 'datafield1', coords: [X1,X2,Y1,Y2], type: 'input | editableLink' }, { name: 'datafield2', ... }]
//
//     
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------


function Flexigrid2(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------

    const rowSize = props.rowSize || '40px';
    const colSize = props.colSize || '300px';
    const gridID = props.id || 'flexi';
    const classRoot = props.classRoot || 'fl';
    const inheritedClasses = props.className || '';
    const cells = props.cells || {};
    const cellsHTML = props.cellsHTML || {};
    const data = props.data || {};
    const onDataChange = props.onDataChange || (() => {});
    const boxes = props.boxes || {};
    const options = props.options || {};
    var spellChecks = [];
    // const globalState = props.globalState || {};

    var handleClickFlexi = options.handleClick || {}; 
    var spellUpdaters = options.spellUpdaters || {};

    // -------- local state variables --------

    let [ spellStatus, setSpellStatus ] = useState(['Empty','Empty','Empty','Empty','Empty','Empty'])

    // -------- start-up code  ---------------

    // ------------- useEffect ---------------

    useEffect( () => {
    

        console.log('Flexigrid2 props', props)
    
        }, [ ]) ; //useEffect

    // ----------- Builder functions ----------

    const htmlItems = Object.entries(cellsHTML).map(([idx,cellObj]) => { 

      // cellObj is a dictionary containing information about what each cell is
      //    .name is the name
      //    .coords are the grid coordinates [X1, X2, Y1, Y2]
      //    .type defines the type of cell: { input, editableLink, img, spellButton }
      
      const { name, coords, type, options } = cellObj;

      const code = cellObj.code || ""

      const value = data[name] || '';

      try {
        spellChecks = (cellObj.spellChecks)
      }
      catch {
        spellChecks = [ spellStatus, setSpellStatus ]
      }

      /* console.log('cellname', name)
      console.log('coords', coords)
      console.log('data', data)
      console.log('value', value)
      console.log('options', options) */

      if (options) {

        if (options.spellUpdaters) {

            spellUpdaters = options.spellUpdaters
          
        }
        if (options.handleClick) {

          handleClickFlexi = options.handleClick
          
        }
      }

      const [colStart, colEnd, rowStart, rowEnd] = coords;

      /* -------- standard text inputs ----------------*/
      if ( type === 'input') {
        return (
          <div  id = { gridID + '-' + name }
                className = { 'flexicell ' + classRoot + '-cell' }
                style = {{
                  width: `${(colEnd - colStart) * parseInt(colSize, 10)}px`,
                  height: '96%',
                  gridRowStart: rowStart,
                  gridColumnStart: colStart,
                  gridRowEnd: `span ${rowEnd - rowStart}`,
                  gridColumnEnd: `span ${colEnd - colStart}` }}
          >
          <input
            type="text"
            className = { 'flexiinput ' + classRoot + '-in' }
            // id = { gridID + '-' + name + '-in' }
            id = { name }  // to make it compatible with GET_DATA
            value={value}
            onChange={(event) => {
                const newData = { ...data };
                newData[name] = sanitizeInput(event.target.value);
                onDataChange(newData);
            }}
        /></div>
        )
      }


      /* -------- spell inputs ----------------*/
      if (type && type.includes('editableLink')) {
        return (
          <div  id = { gridID + '-' + name }
                className = { 'flexicell ' + classRoot + '-cell' }
                style = {{
                  width: `${(colEnd - colStart) * parseInt(colSize, 10)}px`,
                  height: '96%',
                  gridRowStart: rowStart,
                  gridColumnStart: colStart,
                  gridRowEnd: `span ${rowEnd - rowStart}`,
                  gridColumnEnd: `span ${colEnd - colStart}` }}
          >
          <EditableLink id={name} initial={value} spellUpdaters={spellUpdaters}
              data={data} onDataChange={onDataChange}
              globalState={props.globalState}
              />
          { (type && type.includes('spell')) ? <SpellCheckGroup spellChecks={spellChecks} spell_ID={name} /> : <></> }
        </div>
        )
      }

      if ( type === 'spellButton') {
        return (
          <div  id = { gridID + '-' + name }
                className = { 'flexicell ' + classRoot + '-cell' }
                style = {{
                  width: `${(colEnd - colStart) * parseInt(colSize, 10)}px`,
                  height: '96%',
                  gridRowStart: rowStart,
                  gridColumnStart: colStart,
                  gridRowEnd: `span ${rowEnd - rowStart}`,
                  gridColumnEnd: `span ${colEnd - colStart}` }}
          >

              <button onClick= { () => handleClickFlexi() } className = 'cf-in cf-spellbutton'>{value}</button>

          </div>
        )
      }

      if ( type === 'img') {
        return (
          <div  id = { gridID + '-' + name }
                className = { 'flexicell ' + classRoot + '-cell' }
                style = {{
                  zIndex: '1050',
                  width: `${(colEnd - colStart) * parseInt(colSize, 10)}px`,
                  height: '96%',
                  gridRowStart: rowStart,
                  gridColumnStart: colStart,
                  gridRowEnd: `span ${rowEnd - rowStart}`,
                  gridColumnEnd: `span ${colEnd - colStart}` }}
          >
          <img 
            src={options.source}
            className = { 'flexicell' + classRoot + '-img' }
            alt=""
            id={ gridID + '-' + name + '-img'}
            style= {{
              zIndex: '1050'
            }}


          />
            </div>
        )
      }

      return (
        <div id={name}
          style = {{
            width: `${(colEnd - colStart) * parseInt(colSize, 10)}px`,
            height: '96%',
            gridRowStart: rowStart,
            gridColumnStart: colStart,
            gridRowEnd: `span ${rowEnd - rowStart}`,
            gridColumnEnd: `span ${colEnd - colStart}`
          }}
        >
          <pre dangerouslySetInnerHTML={{__html: code }} />
        </div> 
      )
    });

    const gridItems = Object.entries(cells).map(([cellName, cell ]) => {

        const [colStart, colEnd, rowStart, rowEnd] = cell;
        const value = data[cellName] || '';

        /*---------  Spells need to be EditableLinks  --------------------------
        <EditableLink initial="" id="mySp11" spellUpdaters={props.spellUpdaters}
        */ 
        // textboxes
        if ( cellName.includes('myAbi') || cellName.includes('myRabi') ) {
          return (
            <div
                id = { gridID + '-' + cellName }
                className = { 'flexicell ' + classRoot + '-cell' }
                style = {{
                    width: `${(colEnd - colStart) * parseInt(colSize, 10)}px`,
                    height: '96%',
                    gridRowStart: rowStart,
                    gridColumnStart: colStart,
                    gridRowEnd: `span ${rowEnd - rowStart}`,
                    gridColumnEnd: `span ${colEnd - colStart}`,
                }}
            >
              <textarea
                className = { classRoot + '-charlong-textbox' }
                id = { gridID + '-' + cellName + '-in' }
                // style={{ fontSize: `${fontSize}pt`, }}
                value={value}
                onChange={(event) => {
                    const newData = { ...data };
                    newData[cellName] = sanitizeInput(event.target.value);
                    onDataChange(newData);
                }}
              />
            </div>
            );

        }

        // code to calculate font Size automatically
        // const fontSize = Math.min(parseInt(colSize, 10)/ 1.8, 16);
        // height: `${(rowEnd - rowStart) * parseInt(rowSize, 10)}px`,
        // primary return statement. text input inside of a div

        return (

        <div
            id = { gridID + '-' + cellName }
            className = { 'flexicell ' + classRoot + '-cell' }
            style = {{
                width: `${(colEnd - colStart) * parseInt(colSize, 10)}px`,
                height: '96%',
                gridRowStart: rowStart,
                gridColumnStart: colStart,
                gridRowEnd: `span ${rowEnd - rowStart}`,
                gridColumnEnd: `span ${colEnd - colStart}`,
            }}
        >

          <input
            type="text"
            className = { 'flexiinput ' + classRoot + '-in' }
            // id = { gridID + '-' + name + '-in' }
            id = { cellName }  // to make it compatible with GET_DATA
            // style={{ fontSize: `${fontSize}pt`, }}
            value={value}
            onChange={(event) => {
                const newData = { ...data };
                newData[cellName] = sanitizeInput(event.target.value);
                onDataChange(newData);
            }}
          />
        </div>
        );
    });

    const boxItems = Object.entries(boxes).map(([boxName, cell]) => {
      let [colStart, colEnd, rowStart, rowEnd] = cell;
      return (
        <div
              className = { 'flexibox ' }
              id = { classRoot + '-' + boxName }
              style = {{
                  gridRowStart: rowStart,
                  gridColumnStart: colStart,
                  gridRowEnd: `span ${rowEnd - rowStart}`,
                  gridColumnEnd: `span ${colEnd - colStart}`,
                  marginRight: '1px',
                  marginTop: '1px'
              }}
        ></div>
      );      
    });


    function getSpans(cell_def) {
        
        let xValues = [];
        let yValues = [];
        
        for (let key in cell_def) {
          let x1 = cell_def[key][0] || 1;
          let x2 = cell_def[key][1] || 1;
          let y1 = cell_def[key][2] || 1;
          let y2 = cell_def[key][3] || 1;

          xValues.push(x1, x2);
          yValues.push(y1, y2);
        }
        let Xspan = Math.max(...xValues) - Math.min(...xValues) ;
        let Yspan = Math.max(...yValues) - Math.min(...yValues) ;

        return [Xspan, Yspan];
      }

    
    
    // ---------- main return JSX ----------
    //

    const [nCols, nRows] = getSpans(cells);
    //gridTemplateRows: `repeat(${nRows},${rowSize})`

    return (

    // div that is a static grid `nCols` wide x `gridItems.length` long
    //
    // boxItems are border lines overlaid on top

    <div
      className = {'flexigrid ' + classRoot + '-grid ' + inheritedClasses} 
      id =  { gridID }
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${nCols}, ${colSize})`
      }}
    >
      {/* gridTemplateColumns: `repeat(${nCols}, ${colSize})`
          gridTemplateColumns: `repeat(2, 36px) repeat(2, 36px) repeat(2, 36px) repeat(2, 36px)`
      */}
      {htmlItems}
      {gridItems}
      {boxItems}
    </div>
    )

};  // end of function

export default Flexigrid2

/* THIS IS FROM FLEXIGRID 1 - NEEDS UPDATE
Sample usage. Cells defines the input areas, boxes defines the lines
   
   cells={{ datafield1: [ 1,3,1,2 ], datafield2: [1,3,2,3], ... }}

   [ X1, X2, Y1, Y2] where X is the column number and Y is the row number

   the names of 'datafield1' need to be keys in the data dictionary => data[datafield1]
   boxes input does not have this restriction

  <FlexiGrid
    rowSize = '8px'
    colSize = '15px'
    id = {thisCharID+'-grid-sm'}
    classRoot = 'cl'
    cells = {{ name: [1, 10, 1, 4], myClass: [1, 8, 4, 6], myLevel: [8, 10, 4, 6], ... }}
    data = { data }
    onDataChange = {setData}
    boxes={{ box1: [ 1,10,1,10 ], box1A: [1,10,1,4], box2: [ 1,6,10,22 ], box3: [ 6,10,10,22 ], 
              boxHP: [1,10,22,26], boxWeap: [1,10,26,36], boxArm: [1,10,36,46], boxSav: [1,10,46,52],
              boxFol: [1,10,52,56], boxSp1: [1,6,56,63], boxSp2: [6,10,56,63], boxSp3: [1,6,63,69], boxSp4: [6,10,63,69], boxSp5: [1,6,69,74], boxSp6: [6,10,69,74],
              boxMag: [1,10,74,79], boxItems: [1,10,79,89], boxPots: [1,10,89,95], boxRace: [1,10,95,101],
              boxOther: [1,10,101,107], boxGold: [1,10,107,109], boxXP: [1,10,109,113]
        }}
  />
    */