import axios from 'axios';

async function spell_translate ( spellname ) {

    // translate spell abbreviations into full names
    //   by looking up in public/config/spell-abbrevs.json

    const spell_lowered = spellname.toLowerCase();

    // return('Spell not found');

    let S = fetch('config/spell-abbrevs.json')
        .then ((response) => response.json())
        .then ((data) => {
            console.log('Spell lookup data:', data);
            if (spell_lowered in data ) {
                console.log(data[spell_lowered]);
                return(data[spell_lowered]);

            } else {
                console.log('Spell translation not found, using source');
                return 'Spell not found';
            }            
        } )
        
        .catch( (error) => {
            console.error('Error:', error);
            return('Error getting spell lookup');
        } )
    
    return S;
}

export default spell_translate;