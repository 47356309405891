import React, { useState, useEffect, useContext } from 'react';

// import './NavBarButton.css'

import Dropdown from '../common/Dropdown'
import Spell from '../Spell'

import { UserDataContext } from "../../App.js";

import fetch_spell from '../Spell/helpers/fetch_spell.js'
import get_spell_name from '../Spell/helpers/get_spell_name.js';
import { get } from 'jquery';

// ----------------------------------------------------------------------------------
//                         Description
//
//     SpellsButton provides a button and drop-down menu for accessing Spells
//  
//     div == .navbaritem
//       button == #nav-menu-rules .nav-menu-button
//         menu items == .menu .spells-menu > li > button
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function SpellsButton(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------

    // props.state = globalstate = [ currentChar, setChar, loggedIn, layout, setLayout, currentSpellArray, currentSpellDataArray, allSpellsArray ]
    const globalState = props.state;

    const [ currentSpell, setCurrentSpell ] = props.state[5];
    const [ currentSpellData, setCurrentSpellData ] = props.state[6];
    const [ allSpells, setAllSpells, spellFilter, setSpellFilter ] = props.state[7];
    
    const [userData, setUserData] = useContext(UserDataContext);

    // -------- local state variables --------

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {    

        }

        DoSomething();

    }, []); //useEffect

    // ---------- return html (jsx) ----------

    
    // filter the options based on whether the user is logged in or not
    const spell_options = userData.loggedIn ? 
        { tools: ['Search', '---'],
          spells: ['All Spells', 'Cleric', 'Druid', 'Mage', 'Illusion', 'Potion', 'MagicItem', '---'],
          pdf: [ 'Spell-Tables', 'Spells-Cleric', 'Spells-MagicUser', 'Spells-Druid', 'Spells-Illusionist' ] }
        :
        { tools: ['Search', '---'],
          spells: ['All Spells', 'Cleric', 'Druid', 'Mage', 'Illusion', 'Potion', 'MagicItem', '---'],  
          pdf: [ 'Spell-Tables', 'Spells-Cleric', 'Spells-MagicUser', 'Spells-Druid', 'Spells-Illusionist' ] }         
                        
    
    // populate rules and rules_dir arrays
    let rules = [];
    let rules_dir = [];
    for (var directory in spell_options) {
        let rule_list = spell_options[directory];
        for (const rule of rule_list) {
            rules.push(rule);
            rules_dir.push(directory);
        }
    }

    // code for handling the users selection. selection is an integer
    const handleMenu = async (selection) => {

        // tools directory. at the moment, this is search only
        if ( rules_dir[selection] === 'tools') {

            let spell_to_search = prompt('Spell name:');
            if ( spell_to_search === "" ) { return }

            spell_to_search = await get_spell_name(spell_to_search, globalState);
            console.log('spell_to_search:', spell_to_search);

            /*
            // Sentence Case the request
            spell_to_search = spell_to_search.toLowerCase().split(' ').map(function(word) {
                return word.replace(word[0], word[0].toUpperCase());
              }).join(' ');
            */

            let spellClassList = ['Cleric', 'Druid'];

            if ( spell_to_search === 'Feign Death') { spellClassList = ['Mage'] }
            if ( spell_to_search === 'Stone Shape') { spellClassList = ['Mage'] }
            if ( spell_to_search === 'Fire Trap') { spellClassList = ['Mage'] }
            
            let thisSpell = await fetch_spell(spell_to_search, spellClassList )

            if ( thisSpell === "" ) { thisSpell = await fetch_spell( spell_to_search, ['Mage', 'Illusion', 'MagicItem'] ) }

            console.log(thisSpell);

            if ( thisSpell !== "" ) {
                setCurrentSpellData(thisSpell);
                document.getElementById('spell-details').style.display = 'grid';
                // if #mobileSpellDetails exists, display it
                let mobileSpellDetails = document.getElementById('MobileSpellDetail');
                if ( mobileSpellDetails !== null ) { mobileSpellDetails.style.display = 'block' }
            }
            else { alert('spell not found - check spelling') }

        } 
        
        // spells directory (and all others)
        else if  (rules_dir[selection] === 'spells') {
            
            // no action for separators
            if ( rules[selection] === '---' ) { return }

            // toggle all spells UI window for 'All Spells'
            if ( rules[selection] === 'All Spells' ) {
                setSpellFilter({ Class: ['Cleric', 'Druid', 'Mage', 'Illusion', ,'Potion', 'MagicItem'], Lvl: [1,2,3,4,5,6,7,8,9] })
                let allSpellsUI = document.getElementById('all-spells-UI')
                if ( allSpellsUI.style.display === 'block' ) 
                    allSpellsUI.style.display = 'none';
                else
                    allSpellsUI.style.display = 'block';
                
            }

            if ( ['Cleric', 'Druid', 'Illusion', 'Mage', 'Potion', 'MagicItem'].includes(rules[selection]) ) {
                setSpellFilter({ Class: [rules[selection]], Lvl: [1,2,3,4,5,6,7,8,9] })
                let allSpellsUI = document.getElementById('all-spells-UI')
                if ( allSpellsUI.style.display === 'block' ) 
                    allSpellsUI.style.display = 'block';
                else
                    allSpellsUI.style.display = 'block';                
            }


        }
        // open pdf for the rest [old code, can be removed at some point, replaced by AllSpells]
        else if  (rules_dir[selection] === 'pdf') {
            var URL = 'spells/' + rules[selection] + '.pdf';
            var Pdf = require(`../../pdf/${URL}`)
            window.open(Pdf, '_blank').focus();
        }
      }

      const handleSearch = () => {
        alert('Future feature');
      }

    return (
        <>
        <Dropdown
            divClass='navbaritem'
            trigger = {<button id="nav-menu-spells" className="nav-menu-button">Spells</button>}
            menu = { rules.map ( ( rule, idx) => 
                { return <button id={'spell-but-' + idx } onClick={()=>handleMenu(idx)}>{rule}</button>}
            )}
            
            menuClass='menu spells-menu'
        />
        {/* <Spell spellname={ currentSpellData.name } spelldata={ currentSpellData } ID='main-' /> */}
        </>
    )

    };  // end of function

    export default SpellsButton