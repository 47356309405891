import React, { useState, useEffect, useContext } from 'react';

import FlexiGrid2 from '../common/Flexigrid2/Flexigrid2.js';

import './CharsheetFull.css'

import LoadSaveButton from './LoadSaveButton.js'; 

import weaponGrandTotalImage from '../../assets/img/grand-total-2.png';

import { UserDataContext } from "../../App.js";

// ----------------------------------------------------------------------------------
//                         Description
//
//     CharsheetFull is a single character view that encompasses an entire page. It is
//       a replacement/upgrade for Charsheet0
//
//     flatten.js has the text field names
//
//     (c) DXA Development 2023
// ----------------------------------------------------------------------------------

function CharsheetFull(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------
    const [userData, setUserData] = useContext(UserDataContext);
    
    const [currentChar, setChar] = props.state;
    const data = props.data;
    const setData = props.setdata;
    const spellUpdaters = props.spellUpdaters;
    var spellCheckArray=props.spellCheckArray;

    // charID is used to ID the main div, which will be id="{charID}-div"
    const thisCharID = props.charID || '0'

    // -------- local state variables --------
    var [ charImage, setCharImage ] = useState( process.env.PUBLIC_URL + '/img/char/' + currentChar + '.jpg' );
    const [ editing, setEditing ] = useState(false);

    // ------------- event handlers ---------------
    
    const toggleEdit = () => {
        if (!editing) {
            
            // Hide elements with class "editable-link"
            document.querySelectorAll(".editable-link").forEach((element) => {
              element.style.display = "none";
            });
          
            // Show elements with class "editable-input"
            
            setValuesFromButtonsToInputs()

            document.querySelectorAll(".editable-input").forEach((element) => {
              element.style.display = "block"; // Use "block" or "inline" based on your CSS requirements
            });
            
          } else {
            // Show elements with class "editable-link"
            document.querySelectorAll(".editable-link").forEach((element) => {
              element.style.display = "block"; // Use "block" or "inline" based on your CSS requirements
            });
          
            // Hide elements with class "editable-input"
            document.querySelectorAll(".editable-input").forEach((element) => {
              element.style.display = "none";
            });
          }

        setEditing(!editing);
        }

    const handleSpellButtonClick = () => {

        toggleEdit();

      }

    // ------ When going into edit mode, populate the editable fields with current data ------  
    function setValuesFromButtonsToInputs() {
        for (let i = 11; i <= 55; i++) {
          const button = document.getElementById(`mySp${i}-but`);
          const input = document.getElementById(`mySp${i}`);
          
          if (button && input) {
            input.value = button.textContent;
          }
        }
        for (let i = 1; i <= 6; i++) {
          let button = document.getElementById(`myMag${i}-but`);
          let input = document.getElementById(`myMag${i}`);          
          if (button && input) { input.value = button.textContent; }

          button = document.getElementById(`myOth${i}-but`);
          input = document.getElementById(`myOth${i}`);          
          if (button && input) { input.value = button.textContent; }

          button = document.getElementById(`myPot${i}-but`);
          input = document.getElementById(`myPot${i}`);          
          if (button && input) { input.value = button.textContent; }

          button = document.getElementById(`myArm${i}-but`);
          input = document.getElementById(`myArm${i}`);          
          if (button && input) { input.value = button.textContent; }

          button = document.getElementById(`myWeap${i}-but`);
          input = document.getElementById(`myWeap${i}`);          
          if (button && input) { input.value = button.textContent; } 
          
        }
      }

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {  

            console.log('CharsheetFull UPDATE ', data )

            setCharImage(process.env.PUBLIC_URL + '/img/char/' + currentChar + '.jpg');

            

        }

        DoSomething();

        // console.log('CharsheetFull allSpells', props.state[7][0])

    }, [ data, currentChar ]) ; //useEffect

    

    // ---------- return html (jsx) ----------

    // define the grid for this layout

    const spellOptions = {'spellUpdaters': spellUpdaters, 'handleClick': handleSpellButtonClick }

    const cells_html = [ 
        {'name': 'html-test', 'coords': [1,4,1,2], 'code': '<div id="charphoto2"><img src={ charImage }/></div>'},
        {'name': 'gTotImg', 'coords': [8,12,7,9], 'type': 'img', 'options': {'source': weaponGrandTotalImage } },

        {'name': 'ArmTxt', 'coords': [15, 21, 6, 7], 'type': 'spellButton', 'options': {}  },
        {'name': 'myArm1', 'coords': [16, 21, 7, 8], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myArm2', 'coords': [16, 21, 8, 9], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myArm3', 'coords': [16, 21, 9, 10], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myArm4', 'coords': [16, 21, 10, 11], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myArm5', 'coords': [16, 21, 11, 12], 'type': 'editableLink-item', 'options': spellOptions },
        

        {'name': 'WeaTxt', 'coords': [1, 8, 8, 9], 'type': 'spellButton', 'options': {} },
        {'name': 'myWeap1', 'coords': [1, 8, 9, 10], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myWeap2', 'coords': [1, 8, 10, 11], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myWeap3', 'coords': [1, 8, 11, 12], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myWeap4', 'coords': [1, 8, 12, 13], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myWeap5', 'coords': [1, 8, 13, 14], 'type': 'editableLink-item', 'options': spellOptions },


        {'name': 'PotTxt', 'coords': [1, 8, 14, 15], 'type': 'spellButton', 'options': {} },
        {'name': 'myPot1', 'coords': [1, 7, 15, 16], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myPot2', 'coords': [1, 7, 16, 17], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myPot3', 'coords': [1, 7, 17, 18], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myPot4', 'coords': [1, 7, 18, 19], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myPot5', 'coords': [1, 7, 19, 20], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myPot6', 'coords': [1, 7, 20, 21], 'type': 'editableLink-item', 'options': spellOptions },

        {'name': 'MagTxt', 'coords': [8, 14, 14, 15], 'type': 'spellButton', 'options': {} },
        {'name': 'myMag1', 'coords': [8, 14, 15, 16], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myMag2', 'coords': [8, 14, 16, 17], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myMag3', 'coords': [8, 14, 17, 18], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myMag4', 'coords': [8, 14, 18, 19], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myMag5', 'coords': [8, 14, 19, 20], 'type': 'editableLink-item', 'options': spellOptions },

        {'name': 'OthTxt', 'coords': [14, 21, 16, 17], 'type': 'spellButton', 'options': {} },
        {'name': 'myOth1', 'coords': [14, 21, 17, 18], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth2', 'coords': [14, 21, 18, 19], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth3', 'coords': [14, 21, 19, 20], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth4', 'coords': [14, 21, 20, 21], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth5', 'coords': [14, 21, 21, 22], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth6', 'coords': [14, 21, 22, 23], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth7', 'coords': [14, 21, 23, 24], 'type': 'editableLink-item', 'options': spellOptions },

        {'name': 'Sp1Txt', 'coords': [1, 3, 24, 25], 'type': 'spellButton', 'options': {} },
        {'name': 'Sp2Txt', 'coords': [5, 7, 24, 25], 'type': 'spellButton', 'options': {} },
        {'name': 'Sp3Txt', 'coords': [9, 11, 24, 25], 'type': 'spellButton', 'options': {} },
        {'name': 'Sp4Txt', 'coords': [13, 15, 24, 25], 'type': 'spellButton', 'options': {} },
        {'name': 'Sp5Txt', 'coords': [17, 19, 24, 25], 'type': 'spellButton', 'options': {} },
      
        {'name': 'mySp11', 'coords': [1, 5, 25, 26], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[11] },
        {'name': 'mySp12', 'coords': [1, 5, 26, 27], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[12] },
        {'name': 'mySp13', 'coords': [1, 5, 27, 28], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[13] },
        {'name': 'mySp14', 'coords': [1, 5, 28, 29], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[14] },
        {'name': 'mySp15', 'coords': [1, 5, 29, 30], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[15] },
        {'name': 'mySp16', 'coords': [1, 5, 30, 31], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[16] },
        {'name': 'mySp17', 'coords': [1, 5, 31, 32], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[17] },
        {'name': 'mySp18', 'coords': [1, 5, 32, 33], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[18] },
        {'name': 'mySp19', 'coords': [1, 5, 33, 34], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[19] },
        {'name': 'mySp20', 'coords': [1, 5, 34, 35], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[20] },

        {'name': 'mySp21', 'coords': [5, 9, 25, 26], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[21] },
        {'name': 'mySp22', 'coords': [5, 9, 26, 27], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[22] },
        {'name': 'mySp23', 'coords': [5, 9, 27, 28], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[23] },
        {'name': 'mySp24', 'coords': [5, 9, 28, 29], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[24] },
        {'name': 'mySp25', 'coords': [5, 9, 29, 30], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[25] },
        {'name': 'mySp26', 'coords': [5, 9, 30, 31], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[26] },
        {'name': 'mySp27', 'coords': [5, 9, 31, 32], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[27] },
        {'name': 'mySp28', 'coords': [5, 9, 32, 33], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[28] },
        {'name': 'mySp29', 'coords': [5, 9, 33, 34], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[29] },
        {'name': 'mySp30', 'coords': [5, 9, 34, 35], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[30] },

        {'name': 'mySp31', 'coords': [9, 13, 25, 26], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[31] },
        {'name': 'mySp32', 'coords': [9, 13, 26, 27], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[32] },
        {'name': 'mySp33', 'coords': [9, 13, 27, 28], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[33] },
        {'name': 'mySp34', 'coords': [9, 13, 28, 29], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[34] },
        {'name': 'mySp35', 'coords': [9, 13, 29, 30], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[35] },
        {'name': 'mySp36', 'coords': [9, 13, 30, 31], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[36] },
        {'name': 'mySp37', 'coords': [9, 13, 31, 32], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[37] },
        {'name': 'mySp38', 'coords': [9, 13, 32, 33], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[38] },
        {'name': 'mySp39', 'coords': [9, 13, 33, 34], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[39] },
        {'name': 'mySp40', 'coords': [9, 13, 34, 35], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[40] },

        {'name': 'mySp41', 'coords': [13, 17, 25, 26], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[41] },
        {'name': 'mySp42', 'coords': [13, 17, 26, 27], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[42] },
        {'name': 'mySp43', 'coords': [13, 17, 27, 28], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[43] },
        {'name': 'mySp44', 'coords': [13, 17, 28, 29], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[44] },
        {'name': 'mySp45', 'coords': [13, 17, 29, 30], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[45] },
        {'name': 'mySp46', 'coords': [13, 17, 30, 31], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[46] },
        {'name': 'mySp47', 'coords': [13, 17, 31, 32], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[47] },
        {'name': 'mySp48', 'coords': [13, 17, 32, 33], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[48] },
        {'name': 'mySp49', 'coords': [13, 17, 33, 34], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[49] },
        {'name': 'mySp50', 'coords': [13, 17, 34, 35], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[50] },

        {'name': 'mySp51', 'coords': [17, 21, 25, 26], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[51] },
        {'name': 'mySp52', 'coords': [17, 21, 26, 27], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[52] },
        {'name': 'mySp53', 'coords': [17, 21, 27, 28], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[53] },
        {'name': 'mySp54', 'coords': [17,21,28,29], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[54] },            
        {'name': 'mySp55', 'coords': [17,21,29,30], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[55] },

    ]
    
    const cells_layout_main = { 
        name:       [1, 21, 1, 2],

        StrTxt:     [1,3,2,3],      myStr:      [3, 5, 2, 3], myStrBon:   [5, 6, 2, 3],
        IntTxt:     [1,3,3,4],      myInt:      [3, 5, 3, 4], myIntBon:   [5, 6, 3, 4],
        WisTxt:     [1,3,4,5],      myWis:      [3, 5, 4, 5], myWisBon:   [5, 6, 4, 5],
        DexTxt:     [1,3,5,6],      myDex:      [3, 5, 5, 6], myDexBon:   [5, 6, 5, 6],
        ConTxt:     [1,3,6,7],      myCon:      [3, 5, 6, 7], myConBon:   [5, 6, 6, 7],
        ChaTxt:     [1,3,7,8],      myCha:      [3, 5, 7, 8], myChaBon:   [5, 6, 7, 8],

        MovTxt:     [6, 8, 2, 3],   myMov:      [6, 8, 3, 4],
        HeiTxt:     [6, 8, 4, 5],   myHei:      [6, 8, 5 ,6], 
        WeiTxt:     [6, 8, 6, 7],   myWei:      [6, 8, 7, 8],

        ClassTxt:   [8, 10, 2, 3],  myClass:    [9, 13, 2, 3],
        LvlTxt:     [8, 10, 3, 4],  myLevel:    [9, 11, 3, 4],
        XPTxt:      [12, 13, 3, 4],   myXP:       [ 13, 15, 3, 4], myXPbonus: [14,15,2,3], 
        NXPTxt:     [12, 13, 4, 5],  myXPnext:   [13, 15, 4, 5],
        GoldTxt:    [12, 13, 5, 6], myGold:     [13, 15, 5, 6], 

        RaceTxt:    [8, 10, 4, 5],  myRace:     [9, 12, 4, 5],
        AliTxt:     [8, 10, 5, 6],  myAlign:    [9, 11, 5, 6],
        DeiTxt:     [8, 10, 6, 7], myDeity:     [9, 12, 6, 7],
        AgeTxt:     [12, 13, 6, 7], myAge:       [13, 15, 6, 7],
        
        HPTxt:      [15, 16, 2, 3], myHPbase:  [15, 16, 3, 4],
        myHPcurr:   [16, 19, 3, 4], myHPnote:  [16, 21, 2, 3],
        ACTxt:      [15, 6, 4, 5], myAC:      [16, 18, 4, 6],
        BaseTxt:    [18, 19, 4, 5], myRear:    [19, 21, 4, 5],   // myRear => BaseAC *data needs update*
        RearTxt:    [18, 19, 5, 6], mySide:    [19, 21, 5, 6],   // mySide => RearAC *data needs update*
        ToHitTxt:   [8, 10, 7, 8], myToHit:   [10, 12, 7, 8],
        NattTxt:    [12, 13, 7, 8], myNatt:    [13, 15, 7, 8],

        myBaseAC:   [15, 16, 6, 7],   
        myAb1:      [15, 16, 7, 8],  
        myAb2:      [15, 16, 8, 9], 
        myAb3:      [15, 16, 9, 10], 
        myAb4:      [15, 16, 10, 11], 
        myAb5:      [15, 16, 11, 12],
        
        WthTxt:  [8, 10, 8, 9],    WdbTxt: [10, 12, 8, 9],   WdgTxt:  [12, 15, 8, 9],
        myWth1:  [8, 10, 9, 10],   myWdb1: [10, 12, 9, 10],  myWdmg1: [12, 15, 9, 10],
        myWth2:  [8, 10, 10, 11],  myWdb2: [10, 12, 10, 11], myWdmg2: [12, 15, 10, 11],
        myWth3:  [8, 10, 11, 12],  myWdb3: [10, 12, 11, 12], myWdmg3: [12, 15, 11, 12],
        myWth4:  [8, 10, 12, 13],  myWdb4: [10, 12, 12, 13], myWdmg4: [12, 15, 12, 13],
        myWth5:  [8, 10, 13, 14],  myWdb5: [10, 12, 13, 14], myWdmg5: [12, 15, 13, 14],

        /* PotTxt:     [1, 8, 14, 15], */
        myQpo1:     [7, 8, 15, 16], myQpo2:     [7, 8, 16, 17], myQpo3:     [7, 8, 17, 18], myQpo4:     [7, 8, 18, 19], myQpo5: [7, 8, 19, 20], myQpo6:     [7, 8, 20, 21], 

        /* MagTxt:     [8, 14, 14, 15],
        myMag1:     [8, 14, 15, 16], myMag2: [8, 14, 16, 17], myMag3: [8, 14, 17, 18], myMag4: [8, 14, 18, 19], myMag5: [8, 14, 19, 20], */

        /* OthTxt:     [14, 21, 14, 15],
        myOth1:     [14, 21, 15, 16], myOth2: [14, 21, 16, 17], myOth3: [14, 21, 17, 18], myOth4: [14, 21, 18, 19], myOth5: [14, 21, 19, 20], */

        FolTxt:     [1, 8, 21, 22], myFol1: [1, 8, 22, 23], myFol2: [1, 8, 23, 24],
        RacTxt:     [12, 14, 20, 21], myRabi: [8, 14, 20, 24], 

        SaveTxt:    [15, 21, 12, 13],
        SavTxt1:    [15, 16, 13, 14], mySav1:  [16, 17, 13, 14], mySbon1: [17, 18, 13, 14],
        SavTxt2:    [15, 16, 14, 15], mySav2:  [16, 17, 14, 15], mySbon2: [17, 18, 14, 15],
        
        mySbon7:  [14, 15, 14, 15],
        SavTxt4:    [18, 19, 13, 14], mySav4:  [19, 20, 13, 14], mySbon4: [20, 21, 13, 14],
        SavTxt5:    [18, 19, 14, 15], mySav5:  [19, 20, 14, 15], mySbon5: [20, 21, 14, 15],
        
        SavTxt6F:   [14, 15, 15, 16], mySbon6: [15, 18, 15, 16], 
        SavTxt3:    [18, 19, 15, 16], mySav3:  [19, 20, 15, 16], mySbon3: [20, 21, 15, 16],

        myTsp1: [3, 4, 24, 25], myNsp1: [4, 5, 24, 25],
        myTsp2: [7, 8, 24, 25], myNsp2: [8, 9, 24, 25],
        myTsp3: [11, 12, 24, 25], myNsp3: [12, 13, 24, 25],
        myTsp4: [15, 16, 24, 25], myNsp4: [16, 17, 24, 25],
        myTsp5: [19, 20, 24, 25], myNsp5: [20, 21, 24, 25],

        AbiTxt: [17, 21, 30, 31], myAbi: [17, 21, 31, 35]
    };

     const boxez = () => {
        
        const boxes_main = { boxName: [1,21,1,2 ], boxStats: [1,8,2,8], boxStats2: [8,14,2,8], boxStats3: [14,21,2,8], }

        var boxes_temp =  {
            // ...boxes_main,
            boxStrA: [1,3,2,3], boxStrB: [3,5,2,3], boxStrC: [5,6,2,3],
            boxIntA: [1,3,3,4], boxIntB: [3,5,3,4], boxIntC: [5,6,3,4],
            boxWisA: [1,3,4,5], boxWisB: [3,5,4,5], boxWisC: [5,6,4,5],
            boxDexA: [1,3,5,6], boxDexB: [3,5,5,6], boxDexC: [5,6,5,6],
            boxConA: [1,3,6,7], boxConB: [3,5,6,7], boxConC: [5,6,6,7],
            boxChaA: [1,3,7,8], boxChaB: [3,5,7,8], boxChaC: [5,6,7,8],

            boxMov:  [6,8,2,4], boxHei:  [6,8,4,6],  boxWei: [6,8,6,8],

            boxClas: [8,15,2,7], boxHP:   [15,21,2,4],
            boxAC: [15, 21, 4, 6],
            boxTH:   [8,15,7,8],

            boxAC1: [15,16,6,7],boxAC2: [15,16,7,8],boxAC3: [15,16,8,9],boxAC4: [15,16,9,10],boxAC5: [15,16,10,11],boxAC6: [15,16,11,12],

            boxArmTxt: [16,21,6,7],boxArm2: [16,21,7,8],boxArm3: [16,21,8,9],boxArm4: [16,21,9,10],boxArm5: [16,21,10,11],boxArm6: [16,21,11,12],

            boxWeapons:[1, 15, 8, 14], 
            boxWeaTxt: [1,8,8,9],boxWea2: [1,8,9,10],boxWea3: [1,8,10,11],boxWea4: [1,8,11,12],boxWea5: [1,8,12,13],boxWea6: [1,8,13,14],
            boxWthTxt: [8,10,8,9],boxWth2: [8,10,9,10],boxWth3: [8,10,10,11],boxWth4: [8,10,11,12],boxWth5: [8,10,12,13],boxWth6: [8,10,13,14],
            boxWdbTxt: [10,12,8,9],boxWdb2: [10,12,9,10],boxWdb3: [10,12,10,11],boxWdb4: [10,12,11,12],boxWdb5: [10,12,12,13],boxWdb6: [10,12,13,14],
            boxWdgTxt: [12,15,8,9],boxWdg2: [12,15,9,10],boxWdg3: [12,15,10,11],boxWdg4: [12,15,11,12],boxWdg5: [12,15,12,13],boxWdg6: [12,15,13,14],

            boxPon0: [7, 8, 14, 15], boxPon1: [7, 8, 15, 16], boxPon2: [7, 8, 16, 17], boxPon3: [7, 8, 17, 18], boxPon4: [7, 8, 18, 19], boxPon5: [7, 8, 19, 20], boxPon6: [7, 8, 20, 21],
            boxPotTxt: [1, 8, 14, 15], boxPot1: [1, 7, 15, 16], boxPot2: [1, 7, 16, 17], boxPot3: [1, 7, 17, 18], boxPot4: [1, 7, 18, 19], boxPot5: [1, 7, 19, 20], boxPot6: [1, 7, 20, 21],
            boxMagTxt: [8, 15, 14, 15], boxMag2: [8, 14, 15, 16], boxMag3: [8, 14, 16, 17], boxMag4: [8, 14, 17, 18], boxMag5: [8, 14, 18, 19], boxMag6: [8, 14, 19, 20],

            boxOth3: [14,21, 16, 17], boxOth4: [14,21, 17, 18], boxOth5: [14,21, 18, 19], boxOth6: [14,21, 19, 20],
            boxOthTxt: [14, 21, 21, 22], boxOth2: [14,21, 22, 23], 
            
            boxFolTxt: [1,8,21,22], boxFol1: [1,8,22,23], boxFol2: [1,8,23,24],
            boxRacTxt: [12,14,20,21], boxRac1: [8,14,20,24],
            boxSavTxt: [15,21,12,13]
        }
        
        // Boxes for spells
        for (let col = 1; col <= 5; col++) {
            boxes_temp['boxSpt' + col ] = [1 + (col - 1) * 4, 3 + (col - 1) * 4, 24, 25];
            boxes_temp['boxSpq1' + col ] = [3 + (col - 1) * 4, 4 + (col - 1) * 4, 24, 25];
            boxes_temp['boxSpq2' + col ] = [4 + (col - 1) * 4, 5 + (col - 1) * 4, 24, 25];
            for (let row = 1; row <= 10; row++) {
                if ( col === 5 & row > 6) continue; // the final column has "More" insead of spells
                boxes_temp['boxS' + col + row] = [1 + (col - 1) * 4, 5 + (col - 1) * 4, row + 24, row + 25];
            }
        }
        
        return boxes_temp
    }

    // <button className='no-print' style={{position:'absolute',top: '16px', left: '120px', fontSize: '8px', width: '14px', paddingTop: '1', zIndex: 120 }}>S</button>

    return (
        <>
            <div id="charsheet-full">
              <div id="charphoto-div"><img id="charphoto0" src={ charImage } alt='' /></div>
                <FlexiGrid2
                    rowSize = '8px'
                    colSize = '36px'
                    id = {thisCharID+'-grid-full'}
                    classRoot = 'cf'
                    cells = { cells_layout_main }
                    cellsHTML = { cells_html }
                    data = { data }
                    onDataChange = { setData }
                    boxes = { boxez() }
                    globalState = { props.state }
                />

                <LoadSaveButton className='no-print' state={[data, setData, currentChar, setChar ]} spellCheckArray={spellCheckArray} />
            </div>    

        </>
    )

    };  // end of function

    export default CharsheetFull

/* Example usage (from Charsheet05)
    <CharsheetFull state={props.state} data={char1data} setdata={setChar1Data} charID='char1' />
    <CharsheetFull state={props.state} data={char2data} setdata={setChar2Data} charID='char2' />
*/