import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useContext } from 'react';
import { Routes, BrowserRouter as Router, Route } from "react-router-dom"
import Cookies from "js-cookie";
import axios from "axios";

import Home from './pages/Home'
import MobileHome from './pages/MobileHome'
import LandscapeHome from './pages/LandscapeHome.js';
import CharsheetMobile from './components/CharsheetMobile/CharsheetMobile.js'
import Newsletter from './pages/Newsletter'
import Oauth2Handler from './Oauth2Handler.js'

import SongPlayer from './components/SongPlayer.js'

import getAllSpells from './components/Spell/helpers/getAllSpells.js'

import './fonts.css';

export const UserDataContext = React.createContext({username: 'Log In', loggedIn: false });

// const addFont = require('add-font');
// addFont('./assets/fonts/Papyrus.TTF', 'Papyrus');

function App() {
  console.log('.'); console.log('.');
  console.log('--- APP START ---')

  // attempt to load character from cookie
  var cookieChar = 'Welcome to ADM'
    
  try {
    var characterJSON = Cookies.get("currentChar");
    cookieChar = JSON.parse(characterJSON);
  }
  catch (error) {
    cookieChar = 'Welcome to ADM' ;
  }


  // ---------- global state variables ----------

  var [ currentChar, setChar ] = useState( cookieChar );

  var loggedIn = false;   // delete? may only be used in globalState, and has been replaced? by userData.loggedIn
  var discordCount = 0;
  
  const [ layout, setLayout ] = useState("mobile");

  const currentSpellArray= useState("Knock");
  const currentSpellDataArray= useState({});

  const getSpellsArray = useState([])
  const spellsFilter = useState({ Class: ['Cleric', 'Druid', 'Mage', 'Illusion'], Lvl: [1,2,3,4,5,6,7,8,9] })
  const allSpellsArray = [...getSpellsArray, ...spellsFilter]

  // use caution when changing globalState as many components access this via direct indexing, e.g. globalState[6]
  let globalState = [ currentChar, setChar, loggedIn, layout, setLayout, currentSpellArray, currentSpellDataArray, allSpellsArray ];

  const [ userData, setUserData ] = useState(useContext(UserDataContext));

  console.log('App:', currentChar,' cookieChar:',cookieChar);

  
  // ---------- useEffect ----------
  useEffect(() => {

    const fetchSpells = async () => {
      const spells = await getAllSpells(undefined, 'App');
      getSpellsArray[1](spells); // setSpellsArray(getAllSpells);
    };

    fetchSpells();

    document.body.style.setProperty('--vw', document.documentElement.clientWidth)
    document.body.style.setProperty('--vh', document.documentElement.clientHeight)
    document.body.style.setProperty('--orientation', 2)
    const pixelDensity = window.devicePixelRatio;
    
    // console.log("First Load", localStorage.getItem("discord_token"));

    const fetchDiscordData = async () => {
      try {
         
        console.log('Fetching discord data...' + discordCount)
        const result = await axios.get(
          "https://discordapp.com/api/users/@me",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("discord_token")}`,
            },
          }
        );
        if (result) {
          setUserData({username: result.data.global_name, loggedIn: true})
          // setDiscordUser(result.data);
          console.log('Discord fetch result:', result);
        }
      } catch (error) {
        console.log('Discord fetch failed');
        // console.log(error);
      }
    };

    // ------  CODE FOR INITIAL LOAD OF APP -----

    // use devicePixelRatio to detect default orientation
    if (window.devicePixelRatio > 2 )
      setLayout("mobile")
    else
      setLayout("full")

    // only fetch discord data once
    discordCount += 1;
    if (discordCount < 2) 
      fetchDiscordData();
    
    console.log(userData)

    console.log('--vw', document.body.style.getPropertyValue('--vw'))
    console.log('--vh', document.body.style.getPropertyValue('--vh'))
    console.log('--vwlong', document.body.style.getPropertyValue('--viewport-width'))
    console.log('--wdevice', document.body.style.getPropertyValue('--device-width'))
    console.log('--density', window.devicePixelRatio )
    
  }, [ ] );


  // ---------- return HTML (jsx) ----------
  return (
    <div id='main-app'>
      
      <UserDataContext.Provider value={[userData, setUserData]} >
        <Router>
          <Routes>
            
            {layout === 'mobile' ? (
              <Route path='/' element={<MobileHome state={globalState} />} />
              ) : (
              layout === 'landscape' ? (
                <Route path='/' element={<LandscapeHome state={globalState} />} />
              ) : (         
              <Route path='/' element={<Home state={globalState} />} />
            ))}
            <Route path='/p' element={<Home state={ globalState } />} />
            <Route path='/w' element={<Home state={ globalState } />} />
            <Route path='/m' element={<MobileHome state={ globalState } />} />
            <Route path='/oauth2/callback' element={<Oauth2Handler />} />
            <Route path='/News' element={<Newsletter state={ globalState } />} />
          </Routes>
        </Router>

      { userData.loggedIn ? <SongPlayer /> : <></> }
      </UserDataContext.Provider>
    </div>
    
      


  );
}

export default App;
