import React, { useState, useEffect } from 'react';

import './Charsheet01.css'

import Charsheet0 from '../../components/Charsheet0';

import CharsheetFull from '../../components/CharsheetFull';
import CharsheetLandscape from '../../components/CharsheetLandscape.js';
import Spell from '../../components/Spell';
import LOAD_CHAR from '../../components/Charsheet0/helpers/load_char2.js'
import flatten from '../../components/Charsheet0/helpers/flatten.js'


// ----------------------------------------------------------------------------------
//                         Description
//
//     Charsheet01 displays the data for a single character in letter-page format
//     - It is optimized for printing to a sheet of letter paper
//     - Intended for desktop display
//     - shows both the character sheet (charsheetFull), and the spells detail window
//
//     (c) DXA Development 2024
// ----------------------------------------------------------------------------------

function Charsheet01(props) {

    // ------------ parameters ---------------

    // ------ inherited state variables ------

    // props.state = globalstate = [ currentChar, setChar, loggedIn, layout, setLayout, currentSpellArray, currentSpellDataArray ]
    
    var currentChar = props.state[0];
    var setChar = props.state[1];

    const layout = props.state[3];
    console.log('Charsheet01 layout:', layout);

    const [ currentSpell, setCurrentSpell ] = props.state[5];
    const [ currentSpellData, setCurrentSpellData ] = props.state[6];

    var data = props.data;
    var setData = props.setdata;
    var charID = props.charID || 'char0';
    var spellCheckArray=props.spellCheckArray;

    // -------- local state variables --------
    
    var loadAttempts = 0;

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () { 
            
            loadAttempts += 1;
        
            let charData
            if (loadAttempts < 2) {
                console.log("Charsheet 01 load attempt ", loadAttempts);
                charData = await LOAD_CHAR( currentChar, setChar, false );
                
                const flatCharData = flatten(charData)

                // set character data
                setData( flatCharData );
          
                // set spell check data
                if (spellCheckArray) {
                  for (let i=11; i<=55; i++) {
                    const spellCheckVarName = "mySpk" + String(i)
          
                    // the second item in the Array is the update function
                    let updateSpellCheck = spellCheckArray[i][1]
                    
                    const spellChecksThis = flatCharData[spellCheckVarName]
                    
                    // set the data if it exists
                    if (typeof spellChecksThis !== 'undefined') {
                      updateSpellCheck( spellChecksThis )
          
                    } else {
                    // set to Empty if no data
                      console.log('spellCheck data missing')
                      updateSpellCheck(['Empty','Empty','Empty','Empty','Empty','Empty'])
                    }
                      
                  }
                }
              
            }

            // set page title
            if ( currentChar === 'Welcome to ADM' ) {
                document.title = 'Advanced Dungeon Master'
    
            } else {
            document.title = 'ADM: ' + currentChar; 
            };

            console.log('Charsheet01 UPDATE',charData);

        }

        DoSomething();

    }, [ currentChar, setChar, setData ]); //useEffect

    // ---------- handler functions ----------


    // ---------- return html (jsx) ----------
    const showNew = true;


    // <Charsheet0 {...props} spellUpdaters = { [setCurrentSpell, setCurrentSpellData] } />   <= the original charsheet. RIP


    return (
        <>
        { layout === 'landscape' ? (
          <CharsheetLandscape state={props.state} data={ data } setdata={ setData } charID={ charID } spellUpdaters = { [setCurrentSpell, setCurrentSpellData]} spellCheckArray={spellCheckArray} />
          ) : (
            <CharsheetFull state={props.state} data={ data } setdata={ setData } charID={ charID } spellUpdaters = { [setCurrentSpell, setCurrentSpellData]} spellCheckArray={spellCheckArray} />
       ) }
        
        <Spell spellname={ currentSpell } spelldata={ currentSpellData } />
        </>
        )

    };  // end of function

    export default Charsheet01